import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { bold28, bold72 } from "./typography"
const StyledBonusSection = styled.section`
    ${baseGridStyles}
    padding: 0;
    margin-top: 2.4rem;
    background-color: var(--light-purple-darker);
    @media screen and (min-width: ${breakpoints.md}px) {
        max-width: 100%;
    }   

    .repsonsive-video {
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
        }

    }

`

const StyledBonusArticle = styled.article`
    ${baseGridStyles}
`

const StyledBonusHeader = styled.header`
    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 12;
    }
    h1{
        text-align: center;
        color: var(--white);
        span {
            ${bold28}
            display: block;
            @media screen and (min-width: ${breakpoints.md}px) {
                ${bold72}
            }
        }
    }
    

`
const StyledBonusContent = styled.div`
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
    }
    p {
        margin-top: 1.6rem;
        color: var(--white);
    }
    h2 {
        text-align: center;
        color: var(--white);
        padding: 0 0 2.4rem 0;
    }
    ul li{
        color: var(--white);
    }
    & figure .gatsby-image-wrapper {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
`
const StyledDivider = styled.hr`
    position: relative;
    width: 100%;
    grid-column: span 4;
    border: 0px solid #fff;

    &::before {
        content: "&";
        background-color: var(--light-purple-darker);
        position: absolute;
        left: calc(50% - 50px);
        top: -1.6rem;
        width: 100px;
        text-align: center;
        margin: 0 auto;     
        ${bold28}
        color: var(--white);
        display: inline-block;
        z-index: 1;
    }
    &::after {
        content: " ";
        width: 100%;
        position: relative;
        display: inline-block;
        border-bottom: 1px solid #fff;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
    }
`

export {
    StyledBonusSection,
    StyledBonusArticle,
    StyledBonusHeader,
    StyledBonusContent,
    StyledDivider
}