import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  StyledBonusSection,
  StyledBonusArticle,
  StyledBonusHeader,
  StyledBonusContent,
  StyledDivider
} from "../styledComponents/landerBonusPromo"

const HiChiMindfulnessCollectionPromo = (data) => {


  const bonus2Content = data.data.nodes[1].content
  const bonus3Content = data.data.nodes[2].content
  const image1 = {
    imageData: data.data.nodes[0].featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.data.nodes[0].featuredImage?.node?.alt || ``,
    description: data.data.nodes[0].featuredImage?.node?.description || "",
  }
  const image2 = {
    imageData: data.data.nodes[1].featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.data.nodes[1].featuredImage?.node?.alt || ``,
    description: data.data.nodes[1].featuredImage?.node?.description || "",
  }

  const image3 = {
    imageData: data.data.nodes[2].featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.data.nodes[2].featuredImage?.node?.alt || ``,
    description: data.data.nodes[2].featuredImage?.node?.description || "",
  }
  return (
    <>
        <StyledBonusSection>
        <StyledBonusArticle itemScope itemType="http://schema.org/Article">
            <StyledBonusHeader>
                <h1><span>BONUS:</span> Register TODAY and get the following meditations for FREE worth £64.99</h1>
            </StyledBonusHeader>
            <StyledBonusContent itemProp="articleBody">
            <h2>1: {data.data.nodes[0].title}</h2>
            {image1 && image1.imageData && (
                    <figure>
                        <GatsbyImage
                        image={image1.imageData}
                        alt={image1.alt}
                        width="100%"
                        padding-bottom="0" />
                        <caption>{image1.description}</caption>
                    </figure>
                    )}
                <p>The Essential Mindfulness Meditation Collection has 13 guided mindful meditations and three silent bell timers.</p>
                <ul>
                    <li>Finding your feet - 13 min</li>
                    <li>From anxiety to inner peace - 26 min</li>
                    <li>Mindful body scan - 16 min</li>
                    <li>Morning daily intention - 8 min</li>
                    <li>Easy sleep - 19 min</li>
                    <li>Mindful breathing - 12 min</li>
                    <li>Cultivating mindfulness - 15 min</li>
                    <li>Compassion - 11 min</li>
                    <li>Being in my body - 21 min</li>
                    <li>Gratitude - 15 min</li>
                    <li>Activating your senses - 16 min</li>
                    <li>Compassion for me and you - 25 min</li>
                    <li>5, 15 and 30-minute silent bell timers</li>
                </ul>
                <p>All the meditations have been written and narrated by Karina Grant, who has been teaching meditation and healing for nearly 15 years.</p>
            </StyledBonusContent>
            </StyledBonusArticle>
            <StyledDivider />
            <StyledBonusArticle itemScope itemType="http://schema.org/Article">
              <StyledBonusContent itemProp="articleBody">
              <h2>2: {data.data.nodes[1].title}</h2>
              {image2 && image2.imageData && (
                      <figure>
                          <GatsbyImage
                          image={image2.imageData}
                          alt={image2.alt}
                          width="100%"
                          padding-bottom="0" />
                          <caption>{image2.description}</caption>
                      </figure>
                      )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: bonus2Content
                    }}></div>

              </StyledBonusContent>
            </StyledBonusArticle>
            <StyledDivider />
            <StyledBonusArticle itemScope itemType="http://schema.org/Article">
              <StyledBonusContent itemProp="articleBody">
              <h2>3: {data.data.nodes[2].title}</h2>
              {image3 && image3.imageData && (
                      <figure>
                          <GatsbyImage
                          image={image3.imageData}
                          alt={image3.alt}
                          width="100%"
                          padding-bottom="0" />
                          <caption>{image3.description}</caption>
                      </figure>
                      )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: bonus3Content
                    }}></div>
              </StyledBonusContent>
            </StyledBonusArticle>
        </StyledBonusSection>
    </>
  )
}

export default HiChiMindfulnessCollectionPromo
