import React, { useState } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { BaseModalBackground } from "styled-react-modal"
import { useShoppingCart } from 'use-shopping-cart'
import {
  StyledEventBookingButtons,
  StyledEventBookingPassed
} from "./StyledPrimaryEventCard"
import { StyledModal } from "../../styledComponents/modal"
import {
  StyledRegisterNowButton,
  StyledDepositButton,
  StyledCloseModalButton,
  StyledAddEventToCartButton
} from "../../styledComponents/button"
import Logo from "../logo"
import Icon from "../icon"
import DepositRepeatStudent from "../formDepositRepeatStudent"


function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <StyledDepositButton onClick={toggleModal}>Deposit, payment plan or repeat student?</StyledDepositButton>
      
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="deposit-modal"
      >
       <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        <h2><Logo /></h2>
        <h3>Deposit, payment plan or repeat student?</h3>
        <p>Are you a repeat student?</p>
        <ul>
            <li>Quantum-Touch Level 1 repeat price is £160.00</li>
            <li>Quantum-Touch Level 2, Self Created Health or Supercharging price is £199.50</li>
        </ul>

        <p>Please fill in the following form and we will send you a payment link.</p>

        <DepositRepeatStudent eventDate={data.data} />
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;


const SingleEventRegisterButton = props => {
  const { 
    title, 
    featuredImage, 
    uri, 
    excerpt, 
    content,
    datetime, 
    location, 
    timeZone, 
    price, 
    tag, 
    priceSale,
    isLander,
    placement, 
    lastFewDaysEarlyBird,
    stripeUrl,
    stripeUrlEarlyBird,
    stripeUrlDeposit,
    stripeUrlRepeat
  } = props 
  const { addItem, checkoutSingleItem } = useShoppingCart()
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  let input = datetime
  if(input){
    let fields = input.split('_')
    var date = fields[0];
    var time = fields[1];
    var timeNoSec = time.slice(0, -3);
  }

  var workshopDate = new Date(date)
  var daysBefore21 = new Date(workshopDate)
  daysBefore21.setDate(daysBefore21.getDate() - 20)
  var today = new Date()
  today.setDate(today.getDate() - 1)
  let showEarlyBird = false
  if(today <= daysBefore21){
    showEarlyBird = true
  } else {
    showEarlyBird = false
  }

  return (
    <>
        {today <= workshopDate ?
          <StyledEventBookingButtons>
          {showEarlyBird && props.priceSale ?
          <>
            <StyledRegisterNowButton 
            placement={placement}
            onClick={() => {
                navigate(`${stripeUrlEarlyBird}`)
              }
            }>Register</StyledRegisterNowButton>
            </>
          : props.tag[0].slug.includes('becoming-more') ?
          <>
            <StyledRegisterNowButton
                onClick={() => {
                  window.open(`${stripeUrl}`)
                }
            } className="external">Register now <Icon name="external" /></StyledRegisterNowButton>
          </>
          :
          <>
            <StyledRegisterNowButton 
              onClick={() => {
                  navigate(`${stripeUrl}`)
                }
              }>Register now {
                props.lastfewdaysearlybird != null && ' - limited spaces'
              }</StyledRegisterNowButton>
            </>
          }
          </StyledEventBookingButtons>

          :
          <StyledEventBookingPassed>
          <p><strong>Note:</strong> This event has passed, <Link to="/events">please view all upcoming workshops &amp; events</Link>.</p>
          </StyledEventBookingPassed>
        }
    </>
  );
}

export default SingleEventRegisterButton
